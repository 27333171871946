import React, { useState, useEffect } from 'react';
import { Container, Sidebar, Sidenav, Nav, SelectPicker, Divider } from 'rsuite';

import DashboardIcon from '@rsuite/icons/Dashboard';
import LogoutIcon from '@rsuite/icons/legacy/SignOut';
import GroupIcon from '@rsuite/icons/legacy/Group';
import LinkIcon from '@rsuite/icons/legacy/Link';
import InsightsIcon from '@rsuite/icons/legacy/LightbulbO';
import ListIcon from '@rsuite/icons/List';
import LineChartIcon from '@rsuite/icons/LineChart';
import PlusIcon from '@rsuite/icons/Plus';



import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, setSelectedAccount, setSelectedBrand, setSelectedShop, setSelectedWebsite } from './base-redux'

import { localStorageAdapter } from '../../app/localStorage';
import { getAccountType } from '../../app/utils';

const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
        {children}
    </Link>
));



const Navigation = ({ children }) => {
    const navigationItems = [
        { eventKey: '1', label: 'Dashboard', link: "/dashboard" },
        { eventKey: '2', label: 'Clicks costs', link: "/category-clicks" },
        { eventKey: '3', label: 'Clicks logs', link: "/click-logs" },
        { eventKey: '4-1', label: 'Add new', link: "/campaigns" },
        { eventKey: '4-2', label: 'All campaigns', link: "/all-campaigns" },
        { eventKey: '5', label: 'Insights', link: "/insights" },
        { eventKey: '6', label: 'Logout', link: "/logout" },
    ];
    const [activeKey, setActiveKey] = useState("1");
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.base.user);
    const selected_shop = useSelector(state => state.base.selected_shop);
    const selected_account = useSelector(state => state.base.selected_account);
    const selected_brand = useSelector(state => state.base.selected_brand);
    const selected_website = useSelector(state => state.base.selected_website);

    const [accountObj, setAccountObj] = useState(null)

    useEffect(() => {
        if (user && user.accounts && user.accounts.length > 0) {
            for (const account of user.accounts) {
                if (account.id == selected_account)
                    setAccountObj(account)
            }
        }

        for (const item of navigationItems) {
            if (history.location.pathname.includes(item.link)) {
                setActiveKey(item.eventKey)
            }
        }

    }, [selected_account, user, history.location.pathname]);

    return (
        <div className="show-fake-browser sidebar-page">
            <Container>
                <Sidebar
                    style={{ minHeight: "100vh", display: 'flex', flexDirection: 'column' }}
                    width={220}
                    collapsible
                >
                    <Sidenav.Header>
                        <img src={`${process.env.REACT_APP_LOGO}`} alt="logo" />
                    </Sidenav.Header>
                    <Sidenav appearance="subtle">
                        <Sidenav.Body>
                            <Nav
                                activeKey={activeKey}
                                onSelect={setActiveKey}
                            >

                                <Nav.Item as={NavLink} eventKey={navigationItems[0].eventKey} href={navigationItems[0].link} icon={<DashboardIcon />}>
                                    {navigationItems[0].label}
                                </Nav.Item>

                                {/* <Nav.Item as={NavLink} eventKey={navigationItems[1].eventKey} href={navigationItems[1].link} icon={<ListIcon />}>
                                    {navigationItems[1].label}
                                </Nav.Item> */}

                                {getAccountType(user, selected_account) != "brand" ?
                                    < Nav.Item as={NavLink} eventKey={navigationItems[2].eventKey} href={navigationItems[2].link} icon={<LinkIcon />}>
                                        {navigationItems[2].label}
                                    </Nav.Item>
                                    : ""}


                                <Nav.Menu
                                    title="Campaigns" icon={<LineChartIcon />}
                                    onToggle={() => {
                                        if (localStorageAdapter && localStorageAdapter.getItem('existing_user')) {

                                        }
                                    }}
                                >
                                    <Nav.Item as={NavLink} eventKey={navigationItems[3].eventKey} href={navigationItems[3].link} icon={<PlusIcon />}>
                                        {navigationItems[3].label}
                                    </Nav.Item>
                                    <Nav.Item as={NavLink} eventKey={navigationItems[4].eventKey} href={navigationItems[4].link} icon={<ListIcon />}>
                                        {navigationItems[4].label}
                                    </Nav.Item>

                                </Nav.Menu>

                                <Nav.Item as={NavLink} eventKey={navigationItems[5].eventKey} href={navigationItems[5].link} icon={<InsightsIcon />}>
                                    {navigationItems[5].label}
                                </Nav.Item>

                                <Nav.Item onClick={() => dispatch(logoutUser(history))} icon={<LogoutIcon />}>
                                    {navigationItems[6].label}
                                </Nav.Item>


                            </Nav>
                            <Divider />


                            {user && user.accounts && user.accounts.length > 1 ?
                                <>
                                    <div className='pl-3 pb-2 label-pickers-menu'>Account</div>
                                    <div className='px-3 pb-4'>
                                        <SelectPicker
                                            data={user.accounts}
                                            labelKey={"name"}
                                            valueKey={"id"}
                                            style={{ width: 224 }}
                                            menuAutoWidth
                                            searchable={false}
                                            onChange={(value) => {
                                                dispatch(setSelectedAccount(value, user))
                                            }}
                                            value={selected_account}
                                        />
                                    </div>
                                </>
                                : ""}

                            {accountObj && accountObj.websites && accountObj.websites.length > 1 ?
                                <>
                                    <div className='pl-3 pb-2 label-pickers-menu'>Website</div>
                                    <div className='px-3 pb-4'>
                                        <SelectPicker
                                            data={accountObj.websites}
                                            labelKey={"name"}
                                            valueKey={"id"}
                                            style={{ width: 224 }}
                                            menuAutoWidth
                                            searchable={false}
                                            onChange={(value) => {
                                                dispatch(setSelectedWebsite(value, user))
                                            }}
                                            defaultValue={selected_website}
                                        />
                                    </div>
                                </>
                                : ""}

                            {accountObj && accountObj.shops && accountObj.shops.length > 1 ?
                                <>
                                    <div className='pl-3 pb-2 label-pickers-menu'>Shop</div>
                                    <div className='px-3 pb-4'>
                                        <SelectPicker
                                            data={accountObj.shops}
                                            labelKey={"name"}
                                            valueKey={"id"}
                                            style={{ width: 224 }}
                                            menuAutoWidth
                                            searchable={false}
                                            onChange={(value) => {
                                                dispatch(setSelectedShop(value, user))
                                            }}
                                            defaultValue={selected_shop}
                                        />
                                    </div>
                                </>
                                : ""}
                            {accountObj && accountObj.brands && accountObj.brands.length > 1 ?
                                <>
                                    <div className='pl-3 pb-2 label-pickers-menu'>Brand</div>
                                    <div className='px-3 pb-4'>
                                        <SelectPicker
                                            data={accountObj.brands}
                                            labelKey={"name"}
                                            valueKey={"id"}
                                            style={{ width: 224 }}
                                            menuAutoWidth
                                            searchable={false}
                                            onChange={(value) => {
                                                dispatch(setSelectedBrand(value, user))
                                            }}
                                            defaultValue={selected_brand}
                                        />
                                    </div>
                                </>
                                : ""}
                        </Sidenav.Body>
                    </Sidenav>
                </Sidebar>

                <Container className='page-wrap'>
                    {/* <div className='shop-name text-right pr-3 pt-3'>
                        {shopName}
                    </div> */}
                    {children}
                </Container>
            </Container>
        </div >
    );
};

export default Navigation;