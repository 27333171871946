import ptekaApi from '../../app/services';
import { sessionStorageAdapter } from "../../app/sessionStorage"
import { localStorageAdapter } from "../../app/localStorage"
import { formatError, hasPermission } from '../../app/utils'

//CONSTANTS
export const SET_CSRF_ACQUIRED = 'SET_CSRF_ACQUIRED';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const GET_WEBSITE_DETAILS = 'GET_WEBSITE_DETAILS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const BASE_LOADING = 'BASE_LOADING'
export const SET_SELECTED_SHOP = 'SET_SELECTED_SHOP';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
export const SET_SELECTED_WEBSITE = 'SET_SELECTED_WEBSITE';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';



//INITIAL STATE
const INITIAL_STATE = {
    user: {},
    notification: {
        type: "info",
        message: "",
        duration: 4500
    },
    drawers: [],
    modals: [],
    loading: false,
    csrf_token_acquired: false,
    website: {
        currency: ""
    },
    selected_account: null,
    selected_website: null,
    selected_shop: null,
    selected_brand: null
};


//REDUCERS
export const baseReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CSRF_ACQUIRED:
            return {
                ...state,
                csrf_token_acquired: true
            };

        case BASE_LOADING:
            return {
                ...state,
                loading: !state.loading
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload
            };

        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notification: {}
            };

        case GET_WEBSITE_DETAILS:
            return {
                ...state,
                website: action.payload
            };

        case SET_SELECTED_SHOP:
            return {
                ...state,
                selected_shop: action.payload
            };
        case SET_SELECTED_ACCOUNT:
            return {
                ...state,
                selected_account: action.payload
            };
        case SET_SELECTED_WEBSITE:
            return {
                ...state,
                selected_website: action.payload
            };
        case SET_SELECTED_BRAND:
            return {
                ...state,
                selected_brand: action.payload
            };
        default:
            return state;
    }
}


//ACTIONS
export const getCsrfToken = () => dispatch => {
    sessionStorageAdapter.removeItem("csrfToken");
    ptekaApi.get('/user/csrf')
        .then(res => {
            sessionStorageAdapter.setItem("csrfToken", res.data.csrf);
            ptekaApi.defaults.headers.post['X-CSRF-Token'] = res.data.csrf;
            ptekaApi.defaults.headers.patch['X-CSRF-Token'] = res.data.csrf;
            ptekaApi.defaults.headers.delete['X-CSRF-Token'] = res.data.csrf;
            dispatch({ type: SET_CSRF_ACQUIRED });
        })
        .catch(err => dispatch(showError(err)));
};

export const getUserProfile = (history) => dispatch => {
    ptekaApi.get('/user/me').then(res => {
        if (!hasPermission(res.data.data, ["ROLE_ADMIN", "ROLE_CLIENT"])) {
            history.push('/');
            return;
        }
        sessionStorageAdapter.setItem("authenticated", true);
        dispatch({
            type: SET_CURRENT_USER,
            payload: res.data.data
        })
        const user = res.data.data;

        const account_id = localStorageAdapter.getItem("selected_account");
        const website_id = localStorageAdapter.getItem("selected_website");
        const shop_id = localStorageAdapter.getItem("selected_shop");
        const brand_id = localStorageAdapter.getItem("selected_brand");

        if (account_id) {
            dispatch(setSelectedAccount(account_id, user))
        } else if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.accounts &&
            res.data.data.accounts.length > 0 &&
            res.data.data.accounts[0].id
        ) {
            dispatch(setSelectedAccount(res.data.data.accounts[0].id, user))
        }


        if (shop_id) {
            dispatch(setSelectedShop(shop_id, user))
        } else if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.accounts &&
            res.data.data.accounts.length > 0 &&
            res.data.data.accounts[0].shops &&
            res.data.data.accounts[0].shops.length > 0 &&
            res.data.data.accounts[0].shops[0].id
        ) {
            dispatch(setSelectedShop(res.data.data.accounts[0].shops[0].id, user))
        }

        if (website_id) {
            dispatch(setSelectedWebsite(website_id, user))
        } else if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.accounts &&
            res.data.data.accounts.length > 0 &&
            res.data.data.accounts[0].websites &&
            res.data.data.accounts[0].websites.length > 0 &&
            res.data.data.accounts[0].websites[0].id
        ) {
            dispatch(setSelectedWebsite(res.data.data.accounts[0].websites[0].id))
        }

        if (brand_id) {
            dispatch(setSelectedBrand(brand_id, user))
        } else if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.accounts &&
            res.data.data.accounts.length > 0 &&
            res.data.data.accounts[0].brands &&
            res.data.data.accounts[0].brands.length > 0 &&
            res.data.data.accounts[0].brands[0].id
        ) {
            dispatch(setSelectedBrand(res.data.data.accounts[0].brands[0].id, user))
        }


        if (history.location.pathname === "/")
            history.push('/dashboard');

    }).catch(err => {
        sessionStorageAdapter.setItem("authenticated", false);
        if (history.location.pathname !== "/forgot" && !history.location.pathname.includes("/reset-password"))
            history.push('/');
    });
};


export const setSelectedAccount = (account_id, user) => dispatch => {

    let website_id = localStorageAdapter.getItem("selected_website");
    let shop_id = localStorageAdapter.getItem("selected_shop");
    let brand_id = localStorageAdapter.getItem("selected_brand");

    if (user && user.accounts && user.accounts.length > 0)
        for (const acc of user.accounts) {

            if (acc.id == account_id) {


                //check website
                let website_matched = false;
                if (acc.websites && acc.websites.length > 0) {
                    for (const website of acc.websites) {
                        if (website.id == website_id)
                            website_matched = true;
                    }
                }

                if (!website_matched && acc.websites && acc.websites.length > 0) {
                    dispatch(setSelectedWebsite(acc.websites[0].id, user))
                }

                //check shop

                let shop_matched = false;

                if (acc.shops && acc.shops.length > 0) {

                    for (const shop of acc.shops) {
                        if (shop.id == shop_id)
                            website_matched = true;
                    }

                }

                if (!shop_matched && acc.shops && acc.shops.length > 0) {
                    dispatch(setSelectedShop(acc.shops[0].id, user))
                }

                //check brand
                let brand_matched = false;
                if (acc.brand && acc.brands.length > 0) {
                    for (const brand of acc.brands) {
                        if (brand.id == brand_id)
                            website_matched = true;
                    }
                }

                if (!brand_matched && acc.brands && acc.brands.length > 0) {
                    dispatch(setSelectedBrand(acc.brands[0].id, user))
                }


            }
        }


    localStorageAdapter.setItem("selected_account", account_id);
    dispatch({
        type: SET_SELECTED_ACCOUNT,
        payload: parseInt(account_id)
    })
};

export const setSelectedWebsite = (website_id, user) => dispatch => {
    localStorageAdapter.setItem("selected_website", website_id);
    dispatch({
        type: SET_SELECTED_WEBSITE,
        payload: parseInt(website_id)
    })
};
export const setSelectedShop = (shop_id, user) => dispatch => {
    localStorageAdapter.setItem("selected_shop", shop_id);
    dispatch({
        type: SET_SELECTED_SHOP,
        payload: parseInt(shop_id)
    })
};

export const setSelectedBrand = (brand_id, user) => dispatch => {
    localStorageAdapter.setItem("selected_brand", brand_id);
    dispatch({
        type: SET_SELECTED_BRAND,
        payload: parseInt(brand_id)
    })
};



export const getWebsiteDetails = (user) => dispatch => {
    let website_id = null;
    if (localStorageAdapter.getItem("selected_website")) {
        website_id = parseInt(localStorageAdapter.getItem("selected_website"))
    } else {
        if (user && user.accounts && user.accounts.length > 0) {
            for (const account of user.accounts) {
                if (account.websites && account.websites.length > 0) {
                    website_id = parseInt(account.websites[0].id)
                }
            }
        }


    }
    if (website_id)
        ptekaApi.get('/websites/' + website_id).then(res => {
            dispatch({
                type: GET_WEBSITE_DETAILS,
                payload: res.data.data
            })
        }).catch(err => dispatch(showError(err)));
};



export const showError = (err, duration = 4500) => dispatch => {
    dispatch({
        type: SET_NOTIFICATION,
        payload: { type: "error", message: formatError(err), duration: duration }
    })
};

export const showSuccess = (message, duration = 4500) => dispatch => {
    dispatch({
        type: SET_NOTIFICATION,
        payload: { type: "success", message: message, duration: duration }
    })
};

export const removeNotification = () => dispatch => {
    dispatch({ type: REMOVE_NOTIFICATION })
};


export const logoutUser = history => dispatch => {
    ptekaApi
        .get('/user/logout')
        .then(res => {
            if (res.data.message) {
                dispatch(showSuccess(res.data.message, 5000))
                history.push("/");
            }
        })
        .catch(err => dispatch(showError(err)));
};
